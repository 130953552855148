import React, { Component } from "react"
import styles from "./articleImage.module.scss"
import ImgWithLazyload from "src/utils/components/ImageWithLazyload"

export default class ArticleImage extends Component {
  render() {
    const {
      image,
      imageAlt,
      isPreview,
      pictureIsZoomedOnMobile
    } = this.props

    let pictureClass = '';
    if(pictureIsZoomedOnMobile) {
      pictureClass = styles.zoomedImage
    } else {
      pictureClass = styles.image
    }

    return (
      <div className={styles.blockContainer}>
        <ImgWithLazyload isPreview={isPreview} alt={imageAlt} className={pictureClass} src={image} />
      </div>
    )
  }
}
