import React from "react"
import ImgWithLazyload from "src/utils/components/ImageWithLazyload"

import { Typography, makeStyles, Container } from "@material-ui/core"

export default function HeaderBlock({
  title,
  text,
  writerName,
  writerPicture,
  publishingDate,
  isPreview,
}) {
  const styles = useStyles()
  return (
    <Container maxWidth="md" className={styles.container}>
      <div className={styles.content}>
        <Typography variant="h1" className={styles.title1}>
          {title}
        </Typography>
        <Typography className={styles.body1}>{text}</Typography>
        <div className={styles.writerContent}>
          <ImgWithLazyload
            className={styles.writerPicture}
            src={writerPicture}
            alt="Photo de rédacteur"
            isPreview={isPreview}
          />
          <div className={styles.textBox}>
            <Typography variant="body2" className={styles.smallBold}>
              {writerName}
            </Typography>
            <Typography variant="body2">{publishingDate}</Typography>
          </div>
        </div>
      </div>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    padding: "48px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: 24,
    },
  },
  body1: {
    maxWidth: 680,
    margin: "0 auto",
    textAlign: "center",
    lineHeight: "140%",
  },
  content: {
    margin: "0 auto",
    textAlign: "center",
  },
  smallBold: {
    fontWeight: 700,
  },
  title1: {
    color: theme.palette.yellow,
    maxWidth: 680,
    margin: "0 auto",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  writerContent: {
    display: "inline-flex",
    marginTop: 32,
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
    },
  },

  writerPicture: {
    width: 52,
    height: 52,
    borderRadius: 26,
    objectFit: "cover",
  },

  textBox: {
    marginLeft: 8,
    marginTop: "auto",
    marginBottom: "auto",
    textAlign: "left",
  },
}))
