import React from "react"
import ImageWithLazyload from "../../utils/components/ImageWithLazyload"
import { Container, makeStyles } from "@material-ui/core"

export default function PlainImage({ plainImage, imageAlt }) {
  const classes = useStyles()
  return (
    <Container className={classes.container}>
      <ImageWithLazyload src={plainImage} alt={imageAlt} />
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0,
    maxWidth: 680,
  },
}))
