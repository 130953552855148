import React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
import MarkdownContent from "components/markdownContent"
import NewsletterCallToActionBloc from "components/newsletterCallToActionBloc"
import QuickBookingForm from "components/quickBookingForm"
import ArticleImage from "components/articleImage"
import styles from "./template.module.scss"
import FooterBlock from "components/footer"
import HeaderBlock from "components/headerBlock"
import BlockContainer from "components/blockContainer"
import Navigation from "components/navigation"
import LinksBlock from "components/LinksBlock"
import PlainImage from "components/plainImage"
import BlankDivider from "../components/blankDivider"
import USPBackgroundImage from "components/USPBackgroundImage"

export const ArticleTemplate = ({
  seoTitle,
  seoDescription,
  blocks,
  products,
  navigation,
  footerData,
  isPreview,
}) => {
  return (
    <Layout>
      {!isPreview && <SEO title={seoTitle} description={seoDescription} />}
      {/* {!isPreview && <Header links={headerLinks} />} */}
      {!isPreview && <Navigation navigation={navigation} type="article" />}
      {blocks.map(
        (block, index) =>
          (block.type === "headerBlock" && block.isBlockDisplayable && (
            <HeaderBlock {...block} isPreview={isPreview} />
          )) ||
          (block.type === "image" && block.isBlockDisplayable && (
            <BlockContainer key={index}>
              <ArticleImage {...block} isPreview={isPreview} />
            </BlockContainer>
          )) ||
          (block.type === "LinksBlock" && block.isBlockDisplayable && (
            <BlockContainer key={index} noMargin>
              <LinksBlock {...block} isArticle />
            </BlockContainer>
          )) ||
          (block.type === "richText" && block.isBlockDisplayable && (
            <BlockContainer key={index}>
              <MarkdownContent
                pageType="article"
                content={block.content}
                className={styles.templateContainerArticle}
              />
            </BlockContainer>
          )) ||
          (block.type === "plainImage" && block.isBlockDisplayable && (
            <BlockContainer key={index}>
              <PlainImage alt={block.imageAlt} plainImage={block.plainImage} />
            </BlockContainer>
          )) ||
          (block.type === "newsletterCallToActionBloc" &&
            block.isBlockDisplayable && (
              <BlockContainer key={index}>
                <NewsletterCallToActionBloc {...block} />
              </BlockContainer>
            )) ||
          (block.type === "quickBookingForm" && block.isBlockDisplayable && (
            <BlockContainer key={index}>
              <QuickBookingForm
                {...block}
                products={products}
                pageType="article"
              />
            </BlockContainer>
          )) ||
          (block.type === "USPBackgroundImage" && block.isBlockDisplayable && (
            <BlockContainer key={index}>
              <USPBackgroundImage {...block} isPreview={isPreview} />
            </BlockContainer>
          )) ||
          (block.type === "blankDivider" && block.isBlockDisplayable && (
            <BlockContainer key={index}>
              <BlankDivider {...block} noMargin />
            </BlockContainer>
          ))
      )}
      {!isPreview && <FooterBlock {...footerData} />}
    </Layout>
  )
}

const Article = ({ data }) => {
  const { seoTitle, seoDescription, blocks } = data.articleData.frontmatter
  const navigation = {
    links: data.headerData.frontmatter.links,
    color: data.headerData.frontmatter.backgroundColor,
    subItemBlock: data.headerData.frontmatter.subItemBlock,
    enabledPhoneRenderer: data.headerData.frontmatter.enabledPhoneRenderer,
  }
  const products = data.productData.nodes
  const footerData = data.footerData.frontmatter
  return (
    <ArticleTemplate
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      blocks={blocks}
      navigation={navigation}
      products={products}
      footerData={footerData}
    />
  )
}

export default Article

export const pageQuery = graphql`
  query($id: String!, $header: String!) {
    articleData: markdownRemark(id: { eq: $id }) {
      frontmatter {
        templateKey
        seoTitle
        seoDescription
        slug
        blocks {
          type
          content
          dividerSize
          isBlockDisplayable
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          plainImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          textBackgroundColor
          links {
            title
            url
          }
          text
          variant
          title
          optInText
          subtitle
          isBackgroundWhite
          pictureIsZoomedOnMobile
          buttonText
          emailPlaceholder
          product
          writerName
          writerPicture {
            extension
            publicURL
          }
          publishingDate
          secondBackgroundColor
          uspMobileImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          uspImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          buttonLink
          imageAlt
        }
      }
    }
    headerData: markdownRemark(frontmatter: { headerName: { eq: $header } }) {
      frontmatter {
        headerName
        backgroundColor
        links {
          link {
            title
            url
            openLinkInNewTab
            picto {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            menuItem {
              icon {
                extension
                publicURL
              }
              link
              title
              subTitle
              subLinks {
                title
                url
              }
            }
            subItemBlock {
              backgroundColor
              title
              subTitle
              url
              link
            }
          }
        }
      }
    }
    footerData: markdownRemark(
      frontmatter: { footerName: { eq: "Default footer" } }
    ) {
      frontmatter {
        navigationBlocks {
          navigationBlock {
            footerBlockTitle
            footerBlockLinks {
              footerBlockLink {
                title
                url
                openLinkInNewTab
              }
            }
          }
        }
        cgv {
          title
          url
          openLinkInNewTab
        }
      }
    }
    productData: allProduct {
      nodes {
        name
        _id
        image
        slug
      }
    }
  }
`
